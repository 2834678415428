
//styles
import '/src/css/index.sass';


//js
import VideoEmbed from '../components/video-embed';
import GlitchBackground from '../components/glitchbackground'

//
export var button;
export var glitch;
export var video1;

const container = document.createElement('div');

export async function addElements($parent) {

	console.log('addElements: start ');

	//main
    container.id = "index-main";

    //embed video
    //let video = new VideoEmbed("Video1",container);

    let canvasContainer = document.createElement('div');
    canvasContainer.id = "canvasContainer";
    container.appendChild(canvasContainer);

    //three js
    glitch = new GlitchBackground('Glitch',canvasContainer);

    //subhead
    let subhead = document.createElement('div');
    subhead.className = 'subhead';
    subhead.id = 'subhead'
    container.appendChild(subhead);

    let subheadLine1 = document.createElement('div');
    subheadLine1.id = 'subheadLine1';
    subheadLine1.className = 'subtitle';
    subheadLine1.innerHTML = 'Breaking through Russian censorship';
    subhead.appendChild(subheadLine1);

    let subheadLine2 = document.createElement('div');
    subheadLine2.id = 'subheadLine2';
    subheadLine2.className = 'subtitle';
    subheadLine2.innerHTML = 'using pirated movie subtitles';
    subhead.appendChild(subheadLine2);

    //button
    button = document.createElement('button');
    button.id = 'button';
    button.innerText = 'More info';
    subhead.appendChild(button);

    //content
    let content = document.createElement('article');
    content.className = 'content';
    content.id = 'content';
    container.appendChild(content);


    ////////////////----------------------------

    let section1 = document.createElement('section');
    section1.id = 'section1';
    section1.className = '.section';
    content.appendChild(section1);

        let st1 = document.createElement('h2');
        st1.innerText = 'Russia\'s digital Iron Curtain';
        st1.id = "st1";
        st1.classList.add('.subtitle');
        section1.appendChild(st1);

        let p1 = document.createElement('p');
        p1.innerText = 'The already repressive Putin regime cracked down further on free press in the wake of the February 2022 invasion of Ukraine. In the following days and weeks Russia blocked international outlets like BBC Russian News Service or RFE/RL and even platforms like Facebook and Instagram. Others left voluntarily – streaming giant Netflix left the country in March 2022 in protest to Russia’s invasion.'
        p1.id = 'p1';
        section1.appendChild(p1);

        let p2 = document.createElement('p');
        p2.innerText = 'This has left millions lost in a bubble of pro-government propaganda. While news is generally accessible via VPN, apps or messenger services like Telegram, many people in Russia simply don’t make the effort to seek out reliable information. A depressing 63% get their news from television, an almost unadulterated source of propaganda.'
        p2.id = 'p2';
        section1.appendChild(p2);

    ////////////////----------------------------

    let section2 = document.createElement('section');
    section2.id = 'section2';
    section2.className = '.section';
    content.appendChild(section2);

        let st2 = document.createElement('h2');
        st2.innerText = 'Targeting people disengaged from news';
        st2.classList.add('.subtitle');
        section2.appendChild(st2);

        let p3 = document.createElement('p');
        p3.innerText = 'Enter reCaptions, a project launched in early summer. The idea is relatively simple: millions of Russians watch pirated movies and TV shows each year and many need to download Russian subtitles. To do so, they go to a myriad of websites, like Opensubtitles.org or ThePrirateBay.org. [Note: reCaptions is keeping its list of used sites confidential].'
        section2.appendChild(p3);

        let p4 = document.createElement('p');
        p4.innerText = 'The core of the idea is to reach Russians who don’t watch the news much. Hacking illegal downloads is an interesting way of sharing info about the events that are really happening in Ukraine instead of what they see on state TV.'
        section2.appendChild(p4);

     ////////////////----------------------------

    //video-embed
    let video = document.createElement('div');
    video.className = 'video';
    video.id = 'video';
    content.appendChild(video);
    video1 = new VideoEmbed("Video1",video);

    
     ////////////////----------------------------

     let section3 = document.createElement('section');
     section3.id = 'section3';
     section3.className = '.section3';
     content.appendChild(section3);

        let s3p1 = document.createElement('p');
        s3p1.id = 's3p1';
        s3p1.innerHTML = 'By integrating news items, like the bombing of the theatre in Mariupol or a train in Kramatorsk, reCaptions brings important information to Russian audiences. The key is to have new items that would be both emotionally impactful and short enough to fit into the subtitle frame – basically a maximum of two lines. Importantly, they also then add the source of the news, for example The Guardian.'
        section3.appendChild(s3p1);

    //footer
    let footer = document.createElement('div');
    footer.className = 'footer';
    footer.id = 'footer';
    footer.innerHTML = '© 2022 recaptions.org';
    content.appendChild(footer);


    
    $parent.appendChild(container);


    console.log('addElements: done ');
}