import { addElements,button,glitch,video1 } from './index-elements.js';

import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger'
import ScrollToPlugin from 'gsap/ScrollToPlugin'

//import Fireman from '/src/assets/euronews-firemen.png';

console.log('index: start');

//init the page
async function initPage() {

    gsap.registerPlugin(ScrollTrigger,ScrollToPlugin);
    
    await addElements(document.body);
    
    //set the inital state
    gsap.set([".webgl","#subheadLine1","#subheadLine2","#button"],{alpha:0});
   
    //build in animation
    let buildIn = gsap.timeline({onComplete:console.log('build in done')}); 
    buildIn.fromTo(".webgl",{scaleY:0.1},{alpha:1,scaleY:1.0,duration:0.7});
    buildIn.fromTo("#subheadLine1",{x:"-=10"},{delay:2,alpha:1,x:0});
    buildIn.fromTo("#subheadLine2",{x:"+=10"},{alpha:1,x:0});
    buildIn.fromTo("#button",{y:"-=5"},{alpha:1,y:0,delay:0.5});

    
    //scroll based animation.
    gsap.to(["#canvasContainer","#subhead"],{
        autoAlpha:0,
        scrollTrigger:{
            trigger:'.webgl',
            start:'top top',
            end:'bottom center',
            scrub:true,
            onEnter: glitchPause,
            onEnterBack: glitchPlay,
            onLeaveBack: glitchPlay,
        }
    });

    // autoplay the video when scolling
    gsap.set(["#Video1"],{
        scrollTrigger:{
            trigger:'#Video1',
            start:'top center',
            end:'top top',
            scrub:true,
            onEnter: videoPlay,
            onEnterBack: videoPlay, 
            onLeave: videoPause,
            onLeaveBack: videoPause,
        }
    })


    // reveal the sections
    var sections = ['#section1','#section2','#section3'];

    gsap.set(sections,{alpha:0});

    for( var i=0; i<sections.length; i++ ) {
        gsap.to(sections[i],{
            autoAlpha:1,
            scrollTrigger:{
                trigger:sections[i],
                start:'bottom bottom',
                end: 'bottom bottom-=75px',
                scrub:true,
            }
        })
    }

    //button stuff
    button.addEventListener('click',buttonClicked);
}

/*******************************************************read more stuff *****************/

function buttonClicked(){
    document.body.style.overflow = 'visible';
    gsap.to(window,{scrollTo:"#content"});
    //glitch.pause();
}


/***************************************************** video auto trigger **************/
function videoPlay() {
    video1.playVideo();
}

function videoPause() {
    video1.pauzeVideo();
}

/***************************************************** glitch auto trigger **************/
function glitchPlay() { 
    glitch.play();
};
function glitchPause() { 
   glitch.pause();
};

document.addEventListener("DOMContentLoaded", initPage());