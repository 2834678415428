

import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import '/src/css/vjs-recaptions.sass';


export default class VideoEmbed {
	
	constructor(id,parent) {
		
		this.id = id;
		this.parent = parent;
		this.type = "VideoEmbed";
		this.player;
		
		this.init();
	}
	init(){
		
		//the container
		let container = document.createElement('div');
		container.id = this.id;
		//container.innerHTML = `${this.id} container`;
		this.parent.appendChild(container);

		//the video
		let video = document.createElement(`video`);
		video.setAttribute('id',`${this.id}1`);
		video.className = `video-js vjs-recaptions`;
   		container.appendChild(video);

   		//let bgrImg = new Image();
   		//bgrImg.src = Debris.src;

   		//video settings
   		const plyprops = {
   			
   			controls:true,
   			autoplay: false,
   			crossorigin:'anonymous',
   			fill:true,
   			muted:false,
   			bigPlayButton:true,
   			aspectRatio: '16:9',
   			userActions:{click:true,doubleClick:false},
   			//poster: '/src/assets/euronews-debris.png',
   			
   			controlBar: {
   				playToggle:true,
   				pictureInPictureToggle:false,
   				descriptionsButton:false,
   				chaptersButton: false,
   				subsCapsButton: false,
   				audioTrackButton: false,
   				volumePanel:true,
   				currentTimeDisplay: false,
   				timeDivider:false,
   				durationDisplay:false,
   				fullscreenToggle: false,
   				remainingTimeDisplay:false,
   				liveDisplay:false,
   				seekToLive: false,
   				playbackRateMenuButton: false,
   				SeekToLive:false,
   				progressControl:{
   					seekbar:false
   				}
   			}
   			
   		}

   		//player 
   		this.player = videojs(video,plyprops,() => { console.log(`video ready`)});
   		this.player.src([
   			{type: 'video/mp4',src:'https://player.vimeo.com/progressive_redirect/playback/755468548/rendition/1080p/file.mp4?loc=external&signature=f37d8738f6800ac19668678a9ce8b11b9064aa939b6b5f58ad58b46eac12e3a9'}
   		]);
	}

	playVideo() {
		//console.log('play video called');
		this.player.play();
	}

	pauzeVideo(){
		//console.log("pause video called");
		this.player.pause();
	}

}