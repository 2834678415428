
import * as THREE from 'three';
import BackgroundImage from './backgroundimage';
import PostEffect from './posteffect';

import Debris from '/src/assets/euronews-debris.png';

export var bgImg;

const normalizeVector2 = require('../common/normalizeVector2').default;

export default class GlitchBackground {
	
	constructor(id,parent) {
		
		this.id = id;
		this.parent = parent;
		this.type = "THREEJS";
		this.state = true;

		this.canvas, this.renderer, this.renderBack1, this.cameraBack, this.postEffect, this.clock, this.sceneBack;
		this.scene, this.camera, this.globalID;
		
		this.init();
	}
	init(){
		
		//the canvas
		this.canvas = document.createElement('canvas');
		this.canvas.className = 'webgl';
		this.parent.appendChild(this.canvas);

		//render stuff
		this.renderer = new THREE.WebGL1Renderer({antialias:false,canvas:this.canvas});
		this.renderBack1 = new THREE.WebGLRenderTarget(document.body.clientWidth, window.innerHeight);
		this.scene = new THREE.Scene();
		this.sceneBack = new THREE.Scene();
		this.camera = new THREE.OrthographicCamera(-1, 1, 1, -1, 0, 1);
		this.cameraBack = new THREE.PerspectiveCamera(45, document.body.clientWidth / window.innerHeight, 1, 2);
		this.clock = new THREE.Clock();


		//background image
		const images = [{src:Debris,c:'Image by Felipe Dana/AP'}];
		bgImg = new BackgroundImage(images[0]);

		//copyright
		const copyright = document.createElement('div');
		copyright.innerHTML = images[0].c;
		copyright.id = 'copyright';
		this.parent.appendChild(copyright);
		
		this.postEffect = new PostEffect(this.renderBack1.texture);


		//and go
	 	this.go();

	}

	go = () => {

	    this.renderer.setSize(document.body.clientWidth, window.innerHeight);
	    this.renderer.setClearColor(0x000000, 1.0);

	    bgImg.init(() => {
	      this.sceneBack.add(bgImg.obj);
	      this.scene.add(this.postEffect.obj);
	    })

	    this.on();
	    this.resizeWindow();
	    
	    this.renderLoop();


	}

	// add the eventlisteners
	on = () => {
		window.addEventListener('resize', () => { this.resizeWindow() });
	}

	//start the renderloop
	renderLoop = () => {

    	this.render();
    	this.globalID = requestAnimationFrame(this.renderLoop);
	}


	//render the frame
	render = () => {

		const time = this.clock.getDelta();
		this.renderer.setRenderTarget(this.renderBack1);
		this.renderer.render(this.sceneBack, this.cameraBack);
		this.postEffect.render(time);
		this.renderer.setRenderTarget(null);
		this.renderer.render(this.scene, this.camera);

	}

	//handele the resize event
	resizeWindow = () => {

		this.canvas.width = document.body.clientWidth;
		this.canvas.height = window.innerHeight;
		this.cameraBack.aspect = document.body.clientWidth / window.innerHeight;
		this.cameraBack.updateProjectionMatrix();
		bgImg.resize();
		this.postEffect.resize();
		this.renderBack1.setSize(document.body.clientWidth, window.innerHeight);
		this.renderer.setSize(document.body.clientWidth, window.innerHeight);

	}
	
	play() {
		//console.log('play glitch called')
		if(!this.state){
			this.renderLoop();
			this.state = true;
		}
	}

	pause() {
		//console.log(`pauseGlitch called`);
		if(this.state) {
			cancelAnimationFrame(this.globalID);
			this.state = false;
		}
	}

}